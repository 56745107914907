import AboutCompany from "./components/homePage/AboutCompany";
import Carasoul from "./components/homePage/Carasoul";
import Footer from "./components/homePage/Footer";
import HeaderPage from "./components/homePage/HeaderPage";
import OurServices from "./components/homePage/OurServices";
import Navbar from "./components/navbar/Navbar";

function App() {
  return (
    <div>
      <Navbar />
      <HeaderPage />
      <AboutCompany />
      <OurServices />
      <Carasoul/>
      <Footer/>
    </div>
  );
}

export default App;
