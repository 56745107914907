import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SubHeader from "../common/SubHeader";
import AppHeader from "../common/AppHeader";
import { airlines } from "../../config/data";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={
        "absolute left-[100%] top-[20%] w-[55px] h-[55px] rounded-full border text-blue-700 border-blue-700 hidden lg:flex justify-center items-center"
      }
      onClick={onClick}
    >
      <FaArrowRight />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={
        "absolute left-[100%] top-[50%] w-[55px] h-[55px] rounded-full border  text-blue-700 border-blue-700 hidden lg:flex justify-center items-center"
      }
      onClick={onClick}
    >
      <FaArrowLeft />
    </div>
  );
};


const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

const Carasoul = () => {
  

  return (
    <section className="flex flex-col gap-10 lg:gap-20 py-20 lg:py-30 w-full justify-center items-center px-10 sm:px-20 lg:px-0">
      <div className="flex w-full md:justify-around flex-wrap gap-10">
        <div className="flex flex-col gap-7 w-full md:w-[400px]">
          <SubHeader color="text-blue-500 font-normal" size="text-xl">
            Solutions for Travel Agency
          </SubHeader>
          <AppHeader size="text-xl md:text-3xl no-underline">
            GDS and Airlines Integrated
          </AppHeader>
        </div>
      </div>

      {/* slider */}
      <div className="flex  flex-col w-full lg:w-[50%]">
        <Slider {...settings} className="w-full flex">
          {airlines.map((it) => {
            return (
              <img
                key={it}
                src={it}
                className="h-[200px] w-full px-2 lg:px-10"
                alt={it}
                width={100}
                height={100}
              />
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default Carasoul;
