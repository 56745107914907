import React, { useState } from "react";

import {
  FaEnvelopeOpenText,
  FaFacebook,
  FaHackerNews,
  FaHamburger,
  FaShopware,
  FaTimes,
} from "react-icons/fa";
import { navbarData } from "../../config/data";
import NavbarCard from "../cards/NavbarCard";

const Navbar = () => {
  const [Menu, setMenu] = useState(true);

  return (
    <>
      <section className="navbar__container ">
        <div className="w-[90%] mx-auto">
          <nav
            onClick={() => setMenu(!Menu)}
            className={
              Menu
                ? "navbar__menu navbar__active 2xl:flex-row flex-col "
                : "navbar__menu"
            }
          >
            <div className="flex justify-between flex-wrap gap-5 md:gap-0 w-[100%] 2xl:w-[55%] ">
              {navbarData.map((it) => {
                return (
                  <NavbarCard
                    key={it.id}
                    title={it.title}
                    subTitle={it.subTitle}
                    icon={it.icon}
                  />
                );
              })}
            </div>
            {/* social icons section  */}
            <hr className="flex sm:hidden w-full border" />
            <div className="flex gap-5  w-[20%]">
              <span className="p-2 bg-blue-100 text-2xl rounded-lg text-blue-800 hover:bg-blue-800 hover:text-blue-100 transition-all duration-300">
                <FaFacebook />
              </span>
              <span className="p-2 bg-blue-100 text-2xl rounded-lg text-blue-800 hover:bg-blue-800 hover:text-blue-100 transition-all duration-300">
                <FaEnvelopeOpenText />
              </span>
            </div>
          </nav>
          <div className="navbar__icons" onClick={() => setMenu(!Menu)}>
            {Menu ? <FaHamburger /> : <FaTimes />}
          </div>
        </div>
      </section>
      <div className="bg-gray-100 py-5 sticky top-0 border-t z-50 w-full hidden lg:flex">
        <div className=" md:w-[90%] mx-auto">
          <img
            src={"/assets/images/logos/otawix.png"}
            alt="logo"
            className="ml-20 w-60"
          />
        </div>
      </div>
    </>
  );
};

export default Navbar;
