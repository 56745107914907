import React, { FC, ReactNode } from 'react'
import AppHeader from '../common/AppHeader'


const OurServiceCard = ({id,title,subTitle,icon}) => {
  return (
    <div className='flex flex-col border-l-2 hover:border-l-2 hover:border-transparent border-blue-200 pl-8 gap-10 w-full lg:w-[350px] group relative py-10 z-10'>
        <div className='flex gap-5 z-20 group-hover:text-white'>
            <span className='text-blue-500 text-5xl group-hover:text-white'>{icon}</span>
            <AppHeader size='no-underline text-2xl group-hover:text-white'>{title}</AppHeader>
        </div>
        <div className='z-20'>
            <p className='text-gray-500 text-sm  group-hover:text-white'>{subTitle}</p>
        </div>
        {/* div for the blue layer  */}
        <div className='bg-blue-700 w-0 group-hover:w-[100%] h-[100%] absolute inset-0  transition-all duration-500 rounded-lg' ></div>
    </div>
  )
}

export default OurServiceCard