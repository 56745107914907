import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";

let logo = "/assets/images/logos/otawix.png";
let rightImg = "/assets/images/shape/slider-shape-4.png";
let leftImg = "/assets/images/shape/footer-shape-1.png";
// import logos from ""
const Footer = () => {
  return (
    <div className="h-[350px] overflow-hidden footer relative flex flex-col justify-between">
      <div className="flex flex-col items-center gap-10">
        <img src={logo} alt="logo" height={300} width={230} />
        <p className="text-white">Otawix Solution Pvt Ltd.</p>
        <div className="flex gap-5 z-20">
          <span className="text-2xl bg-[#0000ff49] p-2 rounded-lg text-blue-300 hover:text-white hover:bg-blue-600">
          <FaFacebook/>
          </span>
          <span className="text-2xl bg-[#0000ff49] p-2 rounded-lg text-blue-300 hover:text-white hover:bg-pink-600">
          <FaInstagram />
          </span>
          <span className="text-2xl bg-[#0000ff49] p-2 rounded-lg text-blue-300 hover:text-white hover:bg-blue-400">
          <FaTwitter />
          </span>
          <span className="text-2xl bg-[#0000ff49] p-2 rounded-lg text-white hover:text-white hover:bg-blue-500">
          <FaLinkedin />
          </span>
          <span className="text-2xl bg-[#0000ff49] p-2 rounded-lg text-red-600 hover:text-white hover:bg-red-500">
          <FaPinterest />
          </span>
        </div>
      </div>
      <div className="flex justify-center border-t border-gray-500 h-[100px]">
        <p className="text-white pt-5">© 2023 Otawix Solution. All Rights Reserved by</p>
      </div>
      {/* position images */}
      <div className="absolute top-[20%] left-[5%] w-fit ">
        <img src={leftImg} alt="logo" height={100} width={130} />
      </div>
      <div className="absolute bottom-0 right-[-100px] w-fit ">
        <img src={rightImg} alt="logo" height={230} width={530} />
      </div>
    </div>
  );
};

export default Footer;
