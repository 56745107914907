import React from "react";
import SubHeader from "../common/SubHeader";
import AppHeader from "../common/AppHeader";
import AppButton from "../forms/AppButton";
import { FaArrowRight } from "react-icons/fa";
import { OurServiceData } from "../../config/data";
import OurServiceCard from "../cards/OurServiceCard";

const OurServices = () => {
  return (
    <section className="bg-slate-100 py-20 flex flex-col items-center gap-16 px-10 sm:px-20 lg:px-0">
      <div className="flex w-full md:justify-around flex-wrap gap-10">
        <div className="flex flex-col gap-7 w-full md:w-[400px]">
        <SubHeader color="text-blue-500 font-normal" size="text-xl">
          OUR SERVICES
        </SubHeader>
        <AppHeader size="text-2xl md:text-3xl no-underline" >What we Offer Variety of OTA Services </AppHeader>
        </div>
        <div>
        <AppButton name="See More" icon={<FaArrowRight/>}/>
        </div>
      </div>

      {/* card section */}
      <section className="flex justify-center items-center w-full md:w-[80%] gap-16 flex-wrap">
        {OurServiceData.map(it=>{
         return   <OurServiceCard icon={it.icon} title={it.title} subTitle={it.subTitle} key={it.id}/>
        })}
      </section>
    </section>
  );
};

export default OurServices;
