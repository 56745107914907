import React, { FC, ReactNode } from "react";
import { IconType } from "react-icons";

const NavbarCard = ({ title, subTitle, icon }) => {
  return (
    <div className="flex items-center gap-5 border-r pr-5">
      <div className="text-3xl text-blue-600">{icon}</div>
      <div>
        <p className="text-slate-400 text-sm">{title}</p>
        <p className="text-sm">{subTitle}</p>
      </div>
    </div>
  );
};

export default NavbarCard;
