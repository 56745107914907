import React from "react";
import SubHeader from "../common/SubHeader";
import AppHeader from "../common/AppHeader";
const AboutCompany = () => {
  return (
    <div className="flex justify-evenly flex-wrap h-auto py-20 gap-10  items-center px-10 sm:px-20 lg:px-0">
      {/* text div  */}
      <div className="flex flex-col gap-10 w-full lg:w-[400px] ">
        <SubHeader
          size="text-[20px] font-normal uppercase"
          color="text-blue-500"
        >
          About Company
        </SubHeader>
        <AppHeader size="text-3xl no-underline">
          Delivering the right solution for your agency
        </AppHeader>
        <p className="text-xl text-gray-500 font-bold">
          Over <span className="text-blue-500">2,500+</span> Satisfied Travel
          Agencies
        </p>
        <hr className="h-[2px] w-full bg-blue-500" />
        <p className="text-gray-500">
          Committed to designing and developing technology solutions for the
          travel agencies. In response to the challenges and needs of the local
          travel agencies.Our systems support every aspect of a travel for
          Pakistan market, stability and boosted efficiency for the most
          demanding industry.
        </p>
      </div>
      {/* img div  */}
      <div className="w-full md:w-[600px] overflow-hidden rounded-lg relative">
        <img
          src="/assets/images/about.jpeg"
          alt="about company image"
          width={600}
          height={600}
          className="h-full w-full"
        />
        <div className="h-[30px] md:h-[35px] w-[90%] rounded-lg absolute bottom-10 right-[5%] bg-blue-700 text-xs md:text-lg text-white animate-bounce flex justify-center items-center font-bold tracking-wide">
          <p>Understand OTA Development Needs</p>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
