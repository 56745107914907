"use client"

import React, { ReactNode } from 'react'


const AppButton = ({name,icon,onClick,type}) => {
  return (
    <div className={`flex justify-evenly items-center bg-blue-700 text-white w-[160px] text-sm h-[45px] rounded-md font-bold group pr-3`} onClick={onClick}><button className='w-full h-full tracking-wider'  type={type}>{name}</button> <span className='transform group-hover:rotate-[180deg] transition-all duration-300'>{icon}</span></div>
  )
}

export default AppButton